import { Mail } from '@mui/icons-material'
import { Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, IconButton, Typography } from '@mui/material'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Center } from '../../components/Center'
import { useErrorHandler } from '../../hooks/error-handler'
import { useAcceptBoundMutation, useTherapistByTokenQuery } from './api'
import { ApolloError } from '@apollo/client'
import { useSnackbar } from 'notistack'


export function AcceptBound() {
  const { token } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { handleError } = useErrorHandler()
  const { enqueueSnackbar } = useSnackbar()

  const { data, loading } = useTherapistByTokenQuery({ invitationToken: atob(token ?? '') })
  const [acceptBound] = useAcceptBoundMutation({ invitationToken: atob(token ?? '') })

  const accept = async () => {
    try {
      await acceptBound()
      enqueueSnackbar('Convite aceito', { variant: 'success' })
      navigate('/')
    } catch (e: ApolloError | any) {
      if (e instanceof ApolloError && e.graphQLErrors.some(a => a.extensions.status === 412)) {
        enqueueSnackbar(e.message, {
          variant: 'warning',
          action: (
            <Button onClick={() => navigate('/profile', { state: { redirectTo: location.pathname } })}>
              Completar Agora
            </Button>
          )
        })
      } else {
        handleError(e, 'Não foi possível aceitar o convite')
      }
    }
  }

  if (loading) return <Center><CircularProgress /></Center>

  return (
    <Center>
      <Card sx={{ width: 600 }}>
        <CardActionArea>
          <CardContent>
            <Typography variant="h4" component="div">
              {data?.therapistByInvitationToken.user.name}
            </Typography>
            <Typography gutterBottom component="div">
              {data?.therapistByInvitationToken.specializations?.join(', ')}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {data?.therapistByInvitationToken.biography}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="secondary" onClick={() => accept()}>
            Aceitar
          </Button>
          <IconButton color='secondary' component={Link} to={`mailto:${data?.therapistByInvitationToken.user.email}`} target='_blank'>
            <Mail />
          </IconButton>
        </CardActions>
      </Card>
    </Center>
  )
}
