import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { DatePicker, TimeField } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { concatDateTime } from '../../utils/date'
import { formatDate } from '../../utils/format'
import { BoundModel, SimpleSession, useBulkCreateAndDeleteSessionsMutation, useBulkCreateSessionsMutation } from './api'

export interface CreateSessionDialogProps {
  bound: BoundModel
  open: boolean
  onClose: () => void
  onCreate?: () => void
}

export function CreateSessionDialog(props: CreateSessionDialogProps) {
  const { handleError } = useErrorHandler()
  const guessNext = guessNextSession(props.bound)

  const [first, setFirst] = useState<Dayjs | null>(guessNext)
  const [last, setLast] = useState<Dayjs | null>(guessNext.add(3, 'month').add(1, 'day'))
  const [startHour, setStartHour] = useState<Dayjs | null>(guessNext)
  const [endHour, setEndHour] = useState<Dayjs | null>(guessNext.add(50, 'minutes'))
  const [frequency, setFrequency] = useState(1)
  const [weekDay, setWeekDay] = useState(guessNext.day())
  const [generated, setGenerated] = useState<SimpleSession[]>([])
  const [examples, setExamples] = useState<string[]>([])
  const [deleteOverlay, setDeleteOverlay] = useState(false)

  useEffect(() => {
    if (!first || !last || !startHour || !endHour)
      return setGenerated([])

    setGenerated(generate(props.bound, first, last, startHour, endHour, frequency, weekDay))
  }, [props.bound, first, last, startHour, endHour, frequency, weekDay])

  useEffect(() => {
    const toExample = generated.map(a => formatDate(a.start))
    if (toExample.length <= 4) {
      setExamples(toExample)
    } else {
      setExamples([...toExample.slice(0, 2), '...', ...toExample.slice(-2)])
    }
  }, [generated])

  const [createSessions] = useBulkCreateSessionsMutation({ sessions: generated })
  const [createAndDeleteSessions] = useBulkCreateAndDeleteSessionsMutation({ sessions: generated, boundId: props.bound.id, startFrom: first?.toDate()!, startTo: last?.toDate()! })

  const save = async () => {
    try {
      if (!first || !last || !startHour || !endHour || !frequency || weekDay === undefined) {
        throw new ExpectedError('Preencha todos os campos')
      }

      if (deleteOverlay) {
        await createAndDeleteSessions()
      } else {
        await createSessions()
      }

      props.onCreate?.()
      props.onClose()
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} >
      <DialogTitle>Sessões recorrentes</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} sm={6}>
            <DatePicker label='A partir de' value={first} onChange={setFirst} views={['day', 'month', 'year']} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker label='Até' value={last} onChange={setLast} views={['day', 'month', 'year']} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimeField label="Início" value={startHour} onChange={setStartHour} ampm={false} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimeField label="Fim" value={endHour} onChange={setEndHour} ampm={false} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="weekday-label">Dia da semana</InputLabel>
              <Select labelId="weekday-label" label="Dia da semana" value={weekDay} onChange={e => setWeekDay(+e.target.value)} >
                <MenuItem value={0}>Domingo</MenuItem>
                <MenuItem value={1}>Segunda-feira</MenuItem>
                <MenuItem value={2}>Terça-feira</MenuItem>
                <MenuItem value={3}>Quarta-feira</MenuItem>
                <MenuItem value={4}>Quinta-feira</MenuItem>
                <MenuItem value={5}>Sexta-feira</MenuItem>
                <MenuItem value={6}>Sábado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField type='number' label="Frequência" value={frequency} onChange={e => setFrequency(+e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch checked={deleteOverlay} onChange={e => setDeleteOverlay(e.target.checked)} />} label="Remover outras sessões no período" />
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' gap={1} component={Paper} variant='outlined' padding={1}>
              {examples.map((a, i) => <Chip key={i} label={a} />)}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={save}>Gerar</Button>
        <Button onClick={props.onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  )
}

function generate(bound: BoundModel, first: Dayjs, last: Dayjs, startTime: Dayjs, endHour: Dayjs, frequency: number, weekDay: number) {
  const sessions: SimpleSession[] = []

  let current = concatDateTime(first.set('day', weekDay), startTime)!
  if (current.isBefore(first))
    current = current.add(1, 'week')

  while (current.isBefore(last)) {
    const start = current.toDate()
    const end = concatDateTime(current, endHour)!.toDate()

    sessions.push({ boundId: bound.id, start, end, sessionValue: bound.defaultSessionValue })
    current = current.add(frequency, 'week')
  }

  return sessions
}

function guessNextSession(bound: BoundModel) {
  const last = dayjs(bound.lastSession?.start)
  const weekday = last.day()

  const guess = dayjs()
    .set('day', weekday)
    .set('hour', last.hour())
    .set('minute', last.minute())
    .set('second', 0)
    .set('millisecond', 0)

  if (guess.isAfter(dayjs()))
    return guess

  return guess.add(1, 'week')
}
