import { Grid, Stack } from '@mui/material'
import { StatBox } from '../../components/StatBox'
import { formatInteger, formatMoney } from '../../utils/format'
import { useSummary } from './api'

export function TherapistDashboard() {

  const summary = useSummary()

  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatInteger(summary.sessionCountLastMonth)} caption='Sessões no último mês' variant='info' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatInteger(summary.sessionCountThisMonth)} caption='Sessões nesse mês (até agora)' variant='info' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatInteger(summary.sessionCountProjection)} caption='Projeção de sessões desse mês' variant='info' />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatMoney(summary.revenueLastMonth)} caption='Faturamento no último mês' variant='success' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatMoney(summary.revenueThisMonth)} caption='Faturamento nesse mês (até agora)' variant='success' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatMoney(summary.revenueProjection)} caption='Projeção de faturamento desse mês' variant='success' />
        </Grid>
      </Grid>

    </Stack>
  )
}
