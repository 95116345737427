import { gql, useMutation, useQuery } from '@apollo/client'

const clientQuery = gql`
  query Bound($id: String!) {
    bound(id: $id) {
        client {
          name
          email
          phoneNo
          birthDate
          cpf
          emergencyContactName
          emergencyContactPhoneNo
          responsibleName
          responsibleCpf
          addressStreet
          addressNumber
          addressComplement
          addressNeighborhood
          addressCity
          addressState
          addressZipCode
        }
    }
  }
`

const clientCreateMutation = gql`
  mutation BoundCreate(
      $name: String!,
      $email: String!,
      $phoneNo: String!,
      $birthDate: DateTime!,
      $cpf: String!,
      $emergencyContactName: String!,
      $emergencyContactPhoneNo: String!,
      $responsibleName: String,
      $responsibleCpf: String,
      $addressZipCode: String,
      $addressStreet: String,
      $addressNumber: String,
      $addressComplement: String,
      $addressNeighborhood: String,
      $addressCity: String,
      $addressState: String
      ) {
    boundCreate(
        data: {
          client: {
            name: $name
            email: $email
            phoneNo: $phoneNo
            birthDate: $birthDate
            cpf: $cpf
            emergencyContactName: $emergencyContactName
            emergencyContactPhoneNo: $emergencyContactPhoneNo
            responsibleName: $responsibleName
            responsibleCpf: $responsibleCpf
            addressZipCode: $addressZipCode
            addressStreet: $addressStreet
            addressNumber: $addressNumber
            addressComplement: $addressComplement
            addressNeighborhood: $addressNeighborhood
            addressCity: $addressCity
            addressState: $addressState
          }
        }
    ) {
        id
    }
  }
`

const clientUpdateMutation = gql`
  mutation BoundUpdate(
      $boundId: String!,
      $name: String!,
      $email: String!,
      $phoneNo: String!,
      $birthDate: DateTime!,
      $cpf: String!,
      $emergencyContactName: String!,
      $emergencyContactPhoneNo: String!,
      $responsibleName: String,
      $responsibleCpf: String,
      $addressZipCode: String,
      $addressStreet: String,
      $addressNumber: String,
      $addressComplement: String,
      $addressNeighborhood: String,
      $addressCity: String,
      $addressState: String
      ) {
    boundUpdate(
        id: $boundId
        data: {
          client: {
            name: $name
            email: $email
            phoneNo: $phoneNo
            birthDate: $birthDate
            cpf: $cpf
            emergencyContactName: $emergencyContactName
            emergencyContactPhoneNo: $emergencyContactPhoneNo
            responsibleName: $responsibleName
            responsibleCpf: $responsibleCpf
            addressZipCode: $addressZipCode
            addressStreet: $addressStreet
            addressNumber: $addressNumber
            addressComplement: $addressComplement
            addressNeighborhood: $addressNeighborhood
            addressCity: $addressCity
            addressState: $addressState
          }
        }
    ) {
        id
    }
  }
`

interface ClientModel {
  name: string
  email: string
  phoneNo: string
  birthDate?: Date
  cpf?: string
  emergencyContactName?: string
  emergencyContactPhoneNo?: string
  responsibleName?: string
  responsibleCpf?: string
  addressZipCode?: string
  addressStreet?: string
  addressNumber?: string
  addressComplement?: string
  addressNeighborhood?: string
  addressCity?: string
  addressState?: string
}

interface ClientQueryInput {
  id: string
}

interface ClientQueryOutput {
  bound: {
    client: ClientModel
  }
}

interface ClientUpdateInput extends ClientModel {
  boundId: string
}

interface ClientUpdateOutput {
  boundUpdate: {
    id: string
  }
}

interface ClientCreateInput extends ClientModel { }

interface ClientCreateOutput {
  boundCreate: {
    id: string
  }
}

export const useClientQuery = (variables?: ClientQueryInput) => useQuery<ClientQueryOutput, ClientQueryInput>(clientQuery, { variables })
export const useUpdateClientMutation = (variables?: ClientUpdateInput) => useMutation<ClientUpdateOutput, ClientUpdateInput>(clientUpdateMutation, { variables })
export const useCreateClientMutation = (variables?: ClientCreateInput) => useMutation<ClientCreateOutput, ClientCreateInput>(clientCreateMutation, { variables })
