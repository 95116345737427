import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { MuiTelInput } from 'mui-tel-input'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/AuthProvider'
import { Center } from '../../components/Center'
import { PasswordField } from '../../components/PasswordField'
import { useErrorHandler } from '../../hooks/error-handler'
import { useCreateUserMutation } from './api'

export function Signup() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [password, setPassword] = useState('')

  const [createUser] = useCreateUserMutation({ name, email, password, phoneNo })

  const navigate = useNavigate()
  const location = useLocation()
  const { handleError } = useErrorHandler()
  const { updateUser } = useAuth()

  async function create() {
    try {
      const { data } = await createUser()
      if (!data) throw new Error('Não foi possível criar o usuário')

      updateUser(
        data.userCreate.tokens.access_token,
        data.userCreate.tokens.refresh_token
      )

      navigate(location.state?.redirectTo ?? '/')
    } catch (e) {
      handleError(e, 'Não foi possível criar o usuário')
    }
  }

  return (
    <Center>
      <Box>
        <Paper sx={{ padding: 2, maxWidth: '800px' }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>Cadastre-se</Typography>
          <Grid container spacing={2}>
            <Grid item md={8} sm={6} xs={12}><TextField id='name' label='Nome' variant='outlined' value={name} onChange={e => setName(e.target.value)} required fullWidth /></Grid>
            <Grid item md={4} sm={6} xs={12}><MuiTelInput id='phoneNo' label='Celular/WhatsApp' variant='outlined' langOfCountryName="pt-BR" defaultCountry='BR' value={phoneNo ?? ''} onChange={setPhoneNo} required fullWidth /></Grid>
            <Grid item sm={6} xs={12}><TextField id='email' label='Email' variant='outlined' value={email} onChange={e => setEmail(e.target.value)} required fullWidth /></Grid>
            <Grid item sm={6} xs={12}><PasswordField id='password' variant='outlined' value={password} onChange={e => setPassword(e.target.value)} required fullWidth /></Grid>
          </Grid >
        </Paper >
        <Box marginTop={2} display='flex' justifyContent='space-between'>
          <Button variant='contained' id='signup' onClick={create}>Criar</Button>
          <Button variant='outlined' id='back' href='/login'>Voltar</Button>
        </Box>
      </Box>
    </Center >
  )
}
