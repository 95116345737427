import { Email, Launch, WhatsApp } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { Center } from '../../components/Center'
import { useErrorHandler } from '../../hooks/error-handler'
import { sendEmail, sendWhatsApp } from '../../utils/contact'
import { formatDate, formatMoney } from '../../utils/format'
import { useInvoiceDeleteMutation, useInvoiceQuery, useInvoiceUpdateMutation } from './api'
import { Receipt } from '../../components/Receipt'
import { SectionPaper } from '../../components/SectionPaper'

export function Invoice() {
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()
  const { invoiceId } = useParams()

  const { data, loading, refetch } = useInvoiceQuery({ invoiceId: invoiceId! })
  const { invoice } = data ?? {}

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)
  const [paymentDate, setPaymentDate] = useState<Dayjs | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>('')

  const [deleteInvoice] = useInvoiceDeleteMutation({ invoiceId: invoiceId! })
  const [updateInvoice] = useInvoiceUpdateMutation()

  useEffect(() => {
    setPaymentDate(dayjs(data?.invoice.dueDate))
    setPaymentMethod(data?.invoice.paymentMethod || '')
  }, [data])

  const handleSave = async (paymentDate: Dayjs | null) => {
    await updateInvoice({
      variables: {
        invoiceId: invoiceId!,
        paymentDate: paymentDate?.toDate() ?? null,
        paymentMethod: paymentMethod
      }
    })
    setPaymentDialogOpen(false)
    await refetch()
  }

  const canDelete = () => {
    return !invoice?.paymentDate
  }

  const handleDelete = async () => {
    try {
      await deleteInvoice()
      navigate('/invoices')
    } catch (e) {
      handleError(e, 'Não foi possível excluir a cobrança')
    }
  }

  if (loading) return <Center><CircularProgress /></Center>
  if (!invoice) return <Navigate to='/invoices' />

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Detalhes da Cobrança
        </Typography>
      </Stack>
      <SectionPaper title="Dados da Cobrança">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="client">Cliente</InputLabel>
              <OutlinedInput
                id="client"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => navigate(`/bounds/${invoice.bound.id}`)} disabled={!invoice.bound}>
                      <Launch />
                    </IconButton>
                    <IconButton edge="end" onClick={() => sendWhatsApp(invoice.bound.client.phoneNo)} disabled={!invoice.bound.client.phoneNo}>
                      <WhatsApp />
                    </IconButton>
                    <IconButton edge="end" onClick={() => sendEmail(invoice.bound.client.email)} disabled={!invoice.bound.client.email}>
                      <Email />
                    </IconButton>
                  </InputAdornment>
                }
                value={invoice.bound.client.name ?? ''}
                inputProps={{ readOnly: true }}
                label="Cliente"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField label="Valor" value={formatMoney(invoice.value)} inputProps={{ readOnly: true }} fullWidth />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField label="Status" value={invoice.paid ? 'Pago' : 'Pendente'} inputProps={{ readOnly: true }} fullWidth />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField label="Cobrança" value={formatDate(invoice.invoiceDate)} inputProps={{ readOnly: true }} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Vencimento" value={formatDate(invoice.dueDate)} inputProps={{ readOnly: true }} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Pagamento" value={formatDate(invoice.paymentDate)} inputProps={{ readOnly: true }} fullWidth />
          </Grid>
        </Grid>
      </SectionPaper>

      <Stack component={Paper} sx={{ p: 2 }} gap={2} direction='row'>
        {invoice.paid ?
          <Button variant='contained' onClick={() => handleSave(null)}>Marcar como Pendente</Button> :
          <Button variant='contained' onClick={() => setPaymentDialogOpen(true)}>Marcar como Pago</Button>
        }
        <Box flexGrow={1} />
        <Button color='error' onClick={handleDelete} disabled={!canDelete()}>Excluir</Button>
      </Stack>

      {invoice.paid && (
        <SectionPaper title="Comprovante de Pagamento">
          <Receipt id={invoice.id}
            paymentDate={invoice.paymentDate}
            paymentMethod={invoice.paymentMethod}
            value={invoice.value} client={invoice.bound.client} />
        </SectionPaper>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Resumo</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell width={1}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.sessions.map(session => (
              <TableRow key={session.id}>
                <TableCell>{formatDate(session.start)}</TableCell>
                <TableCell>{session.summary}</TableCell>
                <TableCell align="right">{formatMoney(session.sessionValue)}</TableCell>
                <TableCell width={1}>
                  <IconButton component={Link} to={`/bounds/${invoice.bound.id}/session/${session.id}`}>
                    <Launch />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={paymentDialogOpen}>
        <DialogTitle>Pagamento</DialogTitle>
        <DialogContent>
          <Stack paddingTop={2} direction='row' gap={2}>
            <DatePicker label="Data de Pagamento" value={paymentDate} onChange={e => setPaymentDate(e)} sx={{ width: '100%' }} views={['day', 'month', 'year']} />
            <Button onClick={() => setPaymentDate(dayjs())}>Hoje</Button>
          </Stack>
          <Stack paddingTop={2} direction='row' gap={2}>
            <TextField label='Forma de Pagamento' variant='outlined' value={paymentMethod ?? ''} sx={{ width: '100%' }} onChange={e => setPaymentMethod(e.target.value)} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => handleSave(paymentDate)}>Salvar</Button>
          <Button onClick={() => setPaymentDialogOpen(false)}>Voltar</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
