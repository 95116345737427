import { Delete, WhatsApp } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Paper, Stack, Switch, TextField, Typography } from '@mui/material'
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useErrorHandler } from '../../hooks/error-handler'
import { useSearchedData } from '../../hooks/searched-data'
import { sendWhatsApp } from '../../utils/contact'
import { formatDate, formatMoney } from '../../utils/format'
import { InvoiceModel, useInvoiceDeleteMutation, useInvoicesQuery } from './api'
import dayjs from 'dayjs'
import { StatBox } from '../../components/StatBox'

export function Invoices() {
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()

  const [onlyUnpaid, setOnlyUnpaid] = useState(true)
  const [search, setSearch] = useState('')

  const { data, loading, refetch } = useInvoicesQuery({ paid: onlyUnpaid ? false : undefined })
  const invoices = useSearchedData<InvoiceModel>(data?.invoices ?? [], search)

  const [unpaidValue, setUnpaidValue] = useState(0)
  const [pastDueValue, setPastDueValue] = useState(0)
  const [paidValue, setPaidValue] = useState(0)

  const [deleteInvoice] = useInvoiceDeleteMutation()

  useEffect(() => {
    setUnpaidValue(invoices
      .filter(a => !a.paymentDate)
      .map(a => a.value)
      .reduce((a, b) => a + b, 0))

    setPastDueValue(invoices
      .filter(a => !a.paymentDate)
      .filter(a => dayjs(a.dueDate).isBefore(dayjs()))
      .map(a => a.value)
      .reduce((a, b) => a + b, 0))

    setPaidValue(invoices
      .filter(a => a.paymentDate)
      .map(a => a.value)
      .reduce((a, b) => a + b, 0)
    )
  }, [invoices])

  const handleDelete = async (invoiceId: string) => {
    try {
      await deleteInvoice({ variables: { invoiceId } })
      await refetch()
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Stack sx={{ height: '100%' }} gap={2}>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Listagem das cobranças
        </Typography>
      </Stack>

      <Stack gap={2} direction='row'>
        <StatBox value={formatMoney(unpaidValue)} caption='Valor em aberto' variant='success' />
        <StatBox value={formatMoney(pastDueValue)} caption='Valor em atraso' variant='warning' />
        {onlyUnpaid ? null : <StatBox value={formatMoney(paidValue)} caption='Valor pago' variant='info' />}
      </Stack>

      <Paper sx={{ p: 2 }} >
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={2}>
          <Stack direction='row' gap={2}>
            <Button component={Link} color='secondary' variant='contained' to='/invoices/new'>Gerar</Button>
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <TextField label='Busca' variant='outlined' value={search} onChange={e => setSearch(e.target.value)} size='small' />
            <FormControlLabel control={<Switch checked={onlyUnpaid} onChange={e => setOnlyUnpaid(e.target.checked)} />} label="Apenas em aberto" />
          </Stack>
        </Stack>
      </Paper>

      <Paper sx={{ width: '100%', flexGrow: 1 }}>
        <DataGrid
          rows={invoices}
          getRowId={c => c.id}
          columns={getColumns(handleDelete)}
          disableRowSelectionOnClick
          disableColumnMenu
          autoPageSize={true}
          onRowClick={e => navigate(`/invoices/${e.id}`)}
          loading={loading}
        />
      </Paper>
    </Stack >
  )
}

const canDelete = (invoice: InvoiceModel) => !invoice.paymentDate

const sendWhatsAppMessage = (invoice: InvoiceModel) => {
  const msg = invoice.sessions.length === 1 ?
    `Olá! Segue a cobrança referente à sessão do dia: ${formatDate(invoice.sessions[0].start)}.\nAbraço e até breve!` :
    `Olá! Segue a cobrança referente às sessões dos dias: ${invoice.sessions.map(a => a.start).sort().map(formatDate).join(', ')}.\nAbraço e até breve!`

  sendWhatsApp(invoice.bound.client.phoneNo, msg)
}

function getColumns(deleteInvoice: (id: string) => Promise<void>): GridColDef[] {
  return [
    { field: 'name', headerName: 'Cliente', flex: 1, valueGetter: b => b.row.bound.client.name },
    {
      field: 'sessions',
      headerName: 'Sessões',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      valueGetter: b => b.row.sessions.length,
    },
    {
      field: 'value',
      headerName: 'Valor',
      align: 'right',
      headerAlign: 'right',
      width: 150,
      valueFormatter: a => formatMoney(a.value),
    },
    {
      field: 'dueDate',
      headerName: 'Vencimento',
      width: 120,
      valueFormatter: a => formatDate(a.value),
    },
    {
      field: 'paid',
      headerName: 'Pago',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value, row }) => <Checkbox checked={value} readOnly={true} />
    },
    {
      field: 'paymentDate',
      headerName: 'Pagamento',
      width: 120,
      valueFormatter: a => formatDate(a.value),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem icon={<WhatsApp />} onClick={() => sendWhatsAppMessage(params.row)} label='Abrir WhatsApp' disabled={!params.row.bound.client.phoneNo} />,
        <GridActionsCellItem icon={<Delete />} onClick={() => { deleteInvoice(params.row.id) }} disabled={!canDelete(params.row)} label='Excluir' />,
      ]
    },
  ]
}
