import { gql, useQuery } from '@apollo/client'

const simpleBoundsQuery = gql`
  query Bounds {
    bounds (filter: {onlyActive: true}) {
      id
      client {name}
    }
  }
`
export interface SimpleBoundModel {
  id: string
  client: { name: string }
}

interface SimpleBoundOutput {
  bounds: SimpleBoundModel[]
}

export const useSimpleBoundQuery = () => useQuery<SimpleBoundOutput>(simpleBoundsQuery)
