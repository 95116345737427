import { PaletteOptions, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'

export function StatBox({ value, caption, variant = 'background' }: { value: string, caption: string, variant?: keyof PaletteOptions }) {
  return (
    <Stack component={Paper} sx={{ backgroundColor: `${variant}.light`, color: `${variant}.contrastText` }} justifyContent='center' padding={1} width='100%'>
      <Typography variant='h5' component='div' textAlign='center' padding={2}>
        {value}
      </Typography>
      <Typography variant='caption' component='div' textAlign='center'>
        {caption}
      </Typography>
    </Stack>
  )
}
