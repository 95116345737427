import { gql, useMutation } from '@apollo/client'

const MUTATION_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(
        email: $email
        password: $password
    ) {
        tokens {access_token, refresh_token}
    }
  }
`

interface LoginInput {
  email: string
  password: string
}

interface LoginOutput {
  login: {
    tokens: {
      access_token: string
      refresh_token: string
    }
  }
}

export const useLoginMutation = (variables?: LoginInput) => useMutation<LoginOutput, LoginInput>(MUTATION_LOGIN, { variables })
