import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { RouterProvider } from 'react-router-dom'
import { AuthProvider } from './auth/AuthProvider'
import { apolloClient, router, theme } from './providers'
import { SnackbarProvider } from 'notistack'

dayjs.locale('pt-br')

export const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient} >
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <SnackbarProvider maxSnack={3}>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </LocalizationProvider>
        </AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
