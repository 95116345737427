import { gql, useMutation, useQuery } from '@apollo/client'

const userQuery = gql`
  query User {
    user {
        id
        name
        email
        phoneNo
        birthDate
        cpf
        emergencyContactName
        emergencyContactPhoneNo
        responsibleName
        responsibleCpf
        addressStreet
        addressNumber
        addressComplement
        addressNeighborhood
        addressCity
        addressState
        addressZipCode
    }
  }
`

const userUpdateMutation = gql`
  mutation UserUpdate(
      $name: String!,
      $phoneNo: String!,
      $birthDate: DateTime!,
      $cpf: String!,
      $emergencyContactName: String!,
      $emergencyContactPhoneNo: String!,
      $responsibleName: String,
      $responsibleCpf: String,
      $addressZipCode: String,
      $addressStreet: String,
      $addressNumber: String,
      $addressComplement: String,
      $addressNeighborhood: String,
      $addressCity: String,
      $addressState: String
      ) {
    userUpdate(
        data: {
            name: $name
            phoneNo: $phoneNo
            birthDate: $birthDate
            cpf: $cpf
            emergencyContactName: $emergencyContactName
            emergencyContactPhoneNo: $emergencyContactPhoneNo
            responsibleName: $responsibleName
            responsibleCpf: $responsibleCpf
            addressZipCode: $addressZipCode
            addressStreet: $addressStreet
            addressNumber: $addressNumber
            addressComplement: $addressComplement
            addressNeighborhood: $addressNeighborhood
            addressCity: $addressCity
            addressState: $addressState
        }
    ) {
        id
    }
  }
`

interface UserModel {
  id: string
  name: string
  email: string
  phoneNo: string
  birthDate?: Date
  cpf?: string
  emergencyContactName?: string
  emergencyContactPhoneNo?: string
  responsibleName?: string
  responsibleCpf?: string
  addressZipCode?: string
  addressStreet?: string
  addressNumber?: string
  addressComplement?: string
  addressNeighborhood?: string
  addressCity?: string
  addressState?: string
}

interface UserQueryInput { }

interface UserQueryOutput {
  user: UserModel
}

type UserUpdateInput = Omit<UserModel, 'id' | 'email'>

interface UserUpdateOutput {
  id: string
}

export const useUserQuery = (variables?: UserQueryInput) => useQuery<UserQueryOutput, UserQueryInput>(userQuery, { variables })
export const useUpdateUserMutation = (variables?: UserUpdateInput) => useMutation<UserUpdateOutput, UserUpdateInput>(userUpdateMutation, { variables })
