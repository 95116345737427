import { Button, Card, Stack, TextField } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/AuthProvider'
import { Center } from '../../components/Center'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { useLoginMutation } from './api'

export function Login() {
  const { handleError } = useErrorHandler()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [login] = useLoginMutation({ email, password })

  const navigate = useNavigate()
  const location = useLocation()
  const { updateUser } = useAuth()


  const onLoginClick = async () => {
    try {
      if (!email) throw new ExpectedError('Email inválido')
      if (!password) throw new ExpectedError('Senha inválida')

      const { data } = await login()
      if (!data?.login) throw new Error('return is empty')

      updateUser(
        data.login.tokens.access_token,
        data.login.tokens.refresh_token
      )
      navigate(location.state?.redirectTo ?? '/')
    } catch (e) {
      handleError(e, 'Verifique seu email e senha')
    }
  }

  const onSignupClick = () => {
    navigate('/signup', { state: { redirectTo: location.state?.redirectTo } })
  }

  return (
    <Center>
      <Card>
        <Stack sx={{ p: 2, minWidth: 500 }}>
          <img src='/logo.svg' alt='psicare' style={{ width: '60%', margin: '40px auto' }} />
          <TextField label="Email" id='email' variant="outlined" sx={{ mb: 2 }} value={email} onChange={e => setEmail(e.target.value)} onKeyDown={k => { if (k.key === 'Enter') onLoginClick() }} />
          <TextField label="Password" id='password' variant="outlined" type='password' sx={{ mb: 2 }} value={password} onChange={e => setPassword(e.target.value)} onKeyDown={k => { if (k.key === 'Enter') onLoginClick() }} />
          <Stack direction='row' spacing={2} >
            <Button id='login' variant="contained" onClick={onLoginClick} fullWidth>Entrar</Button>
            <Button id='signup' variant="outlined" onClick={onSignupClick} fullWidth>Criar conta</Button>
          </Stack>
        </Stack>
      </Card>
    </Center>
  )
}
