import { Stack, Typography } from '@mui/material'
import { useAuth } from '../../auth/AuthProvider'
import { ClientDashboard } from './ClientDashboard'
import { TherapistDashboard } from './TherapistDashboard'
import { Navigate } from 'react-router-dom'

export function Dashboard() {

  const { user } = useAuth()

  if (!user)
    return <Navigate to='/login' />

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent="space-between">
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Olá, {user.name}
        </Typography>
      </Stack>

      {user.isTherapist ? <TherapistDashboard /> : <ClientDashboard />}
    </>
  )
}
