import { EventActions, SchedulerHelpers } from '@aldabil/react-scheduler/types'
import { Box, Button, DialogActions, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material'
import { DatePicker, TimeField } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { ClientSelect } from '../../components/clients-select/ClientsSelect'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { concatDateTime } from '../../utils/date'
import { useCreateSessionMutation, useUpdateSessionMutation } from './api'
import { SessionEvent, sessionToEvent } from './helper'

interface CustomEditorProps {
  scheduler: SchedulerHelpers
}

export const CustomSchedulerEditor = ({ scheduler }: CustomEditorProps) => {
  const { handleError } = useErrorHandler()

  const event = scheduler.edited as SessionEvent | undefined
  const action: EventActions = event ? 'edit' : 'create'
  const { session } = event ?? {}

  const startFromCalendar = dayjs(scheduler.state.start.value)
  const endFromCalendar = startFromCalendar.add(50, 'minutes')

  const [bound, setBound] = useState(session?.bound ?? null)
  const [summary, setSummary] = useState(session?.summary ?? '')
  const [date, setDate] = useState<Dayjs | null>(startFromCalendar)
  const [start, setStart] = useState<Dayjs | null>(startFromCalendar)
  const [end, setEnd] = useState<Dayjs | null>(endFromCalendar)
  const [present, setPresent] = useState<boolean>(session?.present ?? startFromCalendar.isBefore(dayjs()))
  const [paid, setPaid] = useState<boolean>(session?.paid ?? false)

  const [createSession] = useCreateSessionMutation()
  const [updateSession] = useUpdateSessionMutation()

  const handleSubmit = async () => {
    try {
      if (!date || !start || !end) throw new ExpectedError('Selecione uma data e hora válida')

      scheduler.loading(true)

      const startDateTime = concatDateTime(date, start).toDate()
      const endDateTime = concatDateTime(date, end).toDate()

      if (!bound) throw new ExpectedError('Selecione um cliente')

      const save = async () => {
        if (action === 'create') {
          const { data } = await createSession({ variables: { boundId: bound.id, summary, start: startDateTime, end: endDateTime, present, paid } })
          return data?.sessionCreate
        } else {
          const { data } = await updateSession({ variables: { id: session!.id, summary, start: startDateTime, end: endDateTime, present, paid } })
          return data?.sessionUpdate
        }
      }

      const newSession = await save()
      if (!newSession) throw new Error('Erro ao salvar sessão')

      const newEvent = sessionToEvent(newSession)

      scheduler.onConfirm(newEvent, action)
      scheduler.close()
    } catch (error) {
      handleError(error, 'Não foi possível salvar a sessão')
    } finally {
      scheduler.loading(false)
    }
  }

  return (
    <Box minWidth={500}>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Listagem das cobranças
        </Typography>
      </Stack>

      <Stack padding={2} gap={2}>
        <ClientSelect label='Cliente' onChange={(e, nv) => setBound(nv)} value={bound} disabled={action !== 'create'} />
        <TextField label='Resumo' value={summary} onChange={(e) => setSummary(e.target.value)} />
        <DatePicker label="Data" value={date} onChange={setDate} sx={{ width: '100%' }} views={['day', 'month', 'year']} />
        <TimeField label="Início" value={start} onChange={setStart} ampm={false} sx={{ width: '100%' }} />
        <TimeField label="Fim" value={end} onChange={setEnd} ampm={false} sx={{ width: '100%' }} />
        <Stack direction='row' gap={2}>
          <FormControlLabel control={<Switch checked={present} onChange={e => setPresent(e.target.checked)} />} label="Presença" />
          <FormControlLabel control={<Switch checked={paid} onChange={e => setPaid(e.target.checked)} />} label="Pago" />
        </Stack>
      </Stack>
      <DialogActions>
        <Button onClick={handleSubmit} variant='contained'>Salvar</Button>
        <Button onClick={scheduler.close}>Cancelar</Button>
      </DialogActions>
    </Box>
  )
}
