import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { SimpleBoundModel, useSimpleBoundQuery } from './api'

export interface ClientSelectProps extends Omit<AutocompleteProps<SimpleBoundModel, false, false, false>, 'renderInput' | 'options'> {
  label?: string
  placeholder?: string
}

export function ClientSelect(props: ClientSelectProps) {

  const { data } = useSimpleBoundQuery()

  return (
    <Autocomplete
      {...props}
      options={data?.bounds ?? []}
      getOptionLabel={(option) => option.client.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label={props.label} placeholder={props.placeholder} />}
    />
  )
}
