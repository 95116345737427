import { gql, useMutation } from '@apollo/client'

const createUserMutation = gql`
  mutation UserCreate($name: String!, $email: String!, $password: String!, $phoneNo: String!) {
    userCreate(data: { name: $name, email: $email, password: $password, phoneNo: $phoneNo }) {
        tokens {
            access_token
            refresh_token
        }
    }
  }
`

export interface CreateUserInput {
  name: string
  email: string
  password: string
  phoneNo: string
}

export interface CreateUserOutput {
  tokens: {
    access_token: string
    refresh_token: string
  }
}

export const useCreateUserMutation = (variables?: CreateUserInput) => {
  return useMutation<{ userCreate: CreateUserOutput }, CreateUserInput>(createUserMutation, { variables })
}
