import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const formatInteger = (value: number) => (value ?? 0).toLocaleString('pt-BR', { maximumFractionDigits: 0 })
export const formatNumber = (value: number) => (value ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
export const formatMoney = (value: number) => `R$ ${formatNumber(value / 100)}`
export const formatDate = (date: Date | Dayjs | undefined | null) => date ? dayjs(date).format('DD/MM/YYYY') : ''
export const formatTime = (date: Date | Dayjs | undefined | null) => date ? dayjs(date).format('HH:mm') : ''
export const formatTimeRange = (start: Date | Dayjs, end: Date | Dayjs) => `${formatTime(start)} - ${formatTime(end)}`
export const formatRelativeDate = (date: Date | undefined | null) => date ? dayjs(date).fromNow() : ''
