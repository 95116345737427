import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Center } from '../../components/Center'

export function NotFound() {
  const navigate = useNavigate()

  const back = () => navigate(-1)

  return (
    <Center>
      <Card sx={{ width: 350 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Sinto muito,
          </Typography>
          <Typography variant="h5" component="div">
            Página não encontrada
          </Typography>
          <Typography variant="body2">
            Verifique se acessou um link válido. Caso o problema persista, entre em contato conosco.
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={back} size="small">Voltar</Button>
        </CardActions>
      </Card>
    </Center>
  )
}
