import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './AuthProvider'

type ProtectedRouteProps = {
  children: ReactNode
  onlyTherapist: boolean
}

const NavigateToLogin = () => {
  const location = useLocation()
  return <Navigate to="/login" state={{ redirectTo: location.pathname }} replace />
}

const NavigateToHome = () => {
  return <Navigate to="/" replace />
}


export function ProtectedRoute(props: ProtectedRouteProps) {
  const { user } = useAuth()

  if (!user) {
    return <NavigateToLogin />
  }

  if (props.onlyTherapist && !user.isTherapist) {
    return <NavigateToHome />
  }

  return <>{props.children}</>
}
