import { gql, useApolloClient, useQuery } from '@apollo/client'

const invoicesQuery = gql`
  query Sessions($startTo: DateTime!) {
    sessionsToTherapist(query: {criteria: { present: true, paid: false, hasInvoice: false, start: {to: $startTo}}}) {
      id
      bound {
        id
        client {
          name
          phoneNo
        }
      }
      start
      end
      summary
      present
      paid
      sessionValue
    }
  }
`

export interface SessionModel {
  id: string
  bound: {
    id: string
    client: {
      name: string
      phoneNo: string
    }
  }
  start: Date
  end: Date
  summary: string
  present: boolean
  paid: boolean
  sessionValue: number
}

interface InvoicesInput {
  startTo: Date
}

interface InvoicesOutput {
  sessionsToTherapist: SessionModel[]
}

interface InvoiceCreateInput {
  boundId: string
  sessionIds: string[]
  invoiceDate: Date
  dueDate: Date
}


export const useSessionsQuery = (variables?: InvoicesInput) => useQuery<InvoicesOutput, InvoicesInput>(invoicesQuery, { variables })

// TODO: is this the best practice? shold it be refactored?
export const useInvoiceCreate = () => {
  const client = useApolloClient()

  return async (invoices: InvoiceCreateInput[]) => {
    const mutation = gql`
      mutation InvoiceCreate(
        ${invoices.map((invoice, i) => `
          $boundId_${i}: String!,
          $sessionIds_${i}: [String!]!,
          $invoiceDate_${i}: DateTime!,
          $dueDate_${i}: DateTime!
        `).join(',')}
      ) {
        ${invoices.map((invoice, i) => `
          result${i}: invoiceCreate(data: {boundId: $boundId_${i}, sessionIds: $sessionIds_${i}, invoiceDate: $invoiceDate_${i}, dueDate: $dueDate_${i}}) {id}
        `)}
      }
    `

    return await client.mutate({
      mutation,
      variables: invoices
        .reduce((acc, invoice, i) => {
          Object
            .entries(invoice)
            .forEach(([key, value]) => acc[`${key}_${i}`] = value)
          return acc
        }, {} as any)
    })
  }
}
