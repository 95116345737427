import { Button, Card, CardActions, CardContent, Paper, Stack, Typography } from '@mui/material'
import { ClienteSessionModel, useClientSessionsQuery } from './api'
import dayjs from 'dayjs'
import { formatDate, formatRelativeDate, formatTime } from '../../utils/format'
import { sendWhatsApp } from '../../utils/contact'
import { CalendarMonth, Schedule, WhatsApp } from '@mui/icons-material'

interface SessionContact {
  start?: Date,
  therapistPhoneNo: string,
  therapistName: string,
}

interface TherapistModel {
  id: string
  name: string
  license: string
  phoneNo: string
}

interface SessionsByTherapist {
  therapist: TherapistModel,
  sessions: ({
      id: string
      start: Date
  })[]
}

export function ClientDashboard() {

  const { data } = useClientSessionsQuery({ fromDate: dayjs().startOf('hour').toDate() })

  const contact = (sessionContact: SessionContact) => {
    const contactMessage = sessionContact.start
      ? `Olá ${sessionContact.therapistName}! Gostaria de cancelar minha sessão do dia ${formatDate(sessionContact.start)} às ${formatTime(sessionContact.start)}.`
      : `Olá ${sessionContact.therapistName}! Gostaria de falar sobre nossas sessões,`

    sendWhatsApp(
      sessionContact.therapistPhoneNo,
      contactMessage
    )
  }

  const groupSessionsByTherapist = (data: ClienteSessionModel[]): SessionsByTherapist[] => {
    const sessionsByTherapist: SessionsByTherapist[] = []

    data.forEach(({ id, start, bound: {therapist: {user: {id: therapistId, name, phoneNo}, license}}}) => {
      const therapistRecord = sessionsByTherapist.find(record => record.therapist.id === therapistId)
      if (therapistRecord) {
        therapistRecord.sessions.push({
          id,
          start,
        })
      } else {
        sessionsByTherapist.push({
          therapist: {
            id: therapistId,
            name: name,
            license: license,
            phoneNo: phoneNo,
          },
          sessions: [{
            id,
            start,
          }],
        })
      }
    })

    return sessionsByTherapist
  }

  return (
    <Stack gap={2}>
      <Typography variant='h4'>
        Próximas sessões
      </Typography>

      { groupSessionsByTherapist(data?.sessionsToClient ?? []).map(therapistSessions => (
        <Stack gap={2}>
          <Paper>
            <Stack direction='row' padding={2} justifyContent='space-between'>
              <Stack>
                <Typography variant="h5" component="div">
                  {therapistSessions.therapist.name}
                </Typography>
                <Typography color="text.secondary">
                CRP: {therapistSessions.therapist.license}
                </Typography>
              </Stack>
              <Button size="small" onClick={
                () => contact({
                  therapistPhoneNo: therapistSessions.therapist.phoneNo,
                  therapistName: therapistSessions.therapist.name,
                })}
              >
                <Stack gap={1} direction='row'>
                  <WhatsApp />
                Entrar em contato
                </Stack>
              </Button>
            </Stack>
          </Paper>
          <Stack direction='row' flexWrap='wrap' gap={2}>
            {therapistSessions.sessions.map(({id, start}) => (
              <Card key={id} sx={{ width: 250 }}>
                <CardContent>
                  <Stack gap={1} direction='row' alignItems='center'>
                    <CalendarMonth />
                    <Typography variant="h5" component="div">{formatDate(start)}</Typography>
                  </Stack>
                  <Stack gap={1} direction='row' alignItems='center'>
                    <Schedule />
                    <Typography variant="h5" component="div">{formatTime(start)}</Typography>
                  </Stack>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {formatRelativeDate(start)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={
                    () => contact(
                      { start,
                        therapistPhoneNo: therapistSessions.therapist.phoneNo,
                        therapistName: therapistSessions.therapist.name })
                  }
                  >
                    <Stack gap={1} direction='row'>
                      <WhatsApp/>
                      Cancelar
                    </Stack>
                  </Button>
                </CardActions>
              </Card>
            ) )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
