import { gql, useMutation, useQuery } from '@apollo/client'

const therapistByTokenQuery = gql`
  query TherapistByInvitationToken($invitationToken: String!) {
    therapistByInvitationToken(invitationToken: $invitationToken) {
        specializations
        biography
        site
        user {
            name
            email
        }
    }
  }
`

const acceptBoundMutation = gql`
  mutation BoundInvitationAccept($invitationToken: String!) {
    boundInvitationAccept(invitationToken: $invitationToken) {
        id
    }
  }
`
interface TherapistByTokenInput {
  invitationToken: string
}

interface TherapistByTokenOutput {
  therapistByInvitationToken: {
    specializations: string[]
    biography: string
    site: string
    user: {
      name: string
      email: string
    }
  }
}

interface AcceptBoundInput {
  invitationToken: string
}

interface AcceptBoundOutput {
  boundInvitationAccept: {
    id: string
  }
}

export const useTherapistByTokenQuery = (variables?: TherapistByTokenInput) => useQuery<TherapistByTokenOutput>(therapistByTokenQuery, { variables })
export const useAcceptBoundMutation = (variables?: AcceptBoundInput) => useMutation<AcceptBoundOutput, AcceptBoundInput>(acceptBoundMutation, { variables })
