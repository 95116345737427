import { FormControl, Input, InputLabel, OutlinedInput } from '@mui/material'
import { ChangeEventHandler, forwardRef, useId } from 'react'
import { IMaskInput } from 'react-imask'

interface MaskInputProps {
  onChange: (event: { target: { value: string } }) => void
  mask: string
}

const MaskInput = forwardRef<HTMLInputElement, MaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { value } })}
        overwrite
      />
    )
  },
)

export interface TextMaskFieldProps {
  id?: string
  variant?: 'standard' | 'outlined' | 'filled'
  label: string
  value: string
  mask: string
  required?: boolean
  fullWidth?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

export function TextMaskField(props: TextMaskFieldProps) {
  const generatedId = useId()

  const id = props.id ?? generatedId

  const makeField = () => {
    if ((props.variant ?? 'standard') === 'outlined')
      return (
        <OutlinedInput
          label={props.label}
          value={props.value}
          onChange={props.onChange}
          id={id}
          inputComponent={MaskInput as any}
          inputProps={{
            mask: props.mask
          }}
        />
      )
    else
      return (
        <Input
          value={props.value}
          onChange={props.onChange}
          id={id}
          inputComponent={MaskInput as any}
          inputProps={{
            mask: props.mask
          }}
        />)
  }

  return (
    <FormControl variant={props.variant} id={`${id}-form-control`} required={props.required} fullWidth={props.fullWidth}>
      <InputLabel htmlFor={props.id} id={`${id}-label`}>{props.label}</InputLabel>
      {makeField()}
    </FormControl>
  )
}

