import { Email, WhatsApp } from '@mui/icons-material'
import { Button, FormControlLabel, Paper, Stack, Switch, TextField, Typography } from '@mui/material'
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { webBaseUrl } from '../../config'
import { useSearchedData } from '../../hooks/searched-data'
import { sendEmail, sendWhatsApp } from '../../utils/contact'
import { formatDate, formatInteger, formatMoney } from '../../utils/format'
import { BoundModel, useBoundQuery, useInvitationTokenLazyQuery } from './api'


export function Bounds() {
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [onlyActive, setOnlyActive] = useState(true)

  const { data, loading } = useBoundQuery({ onlyActive })
  const bounds = useSearchedData<BoundModel>(data?.bounds ?? [], search)

  const [genInvitationToken] = useInvitationTokenLazyQuery()

  const onRowClick = (e: GridRowParams) => navigate(`/bounds/${e.id.toString()}`)
  const invite = async () => {
    const { data } = await genInvitationToken()
    if (!data) return alert('Erro ao gerar link de convite')

    const token = btoa(data.boundInvitationToken)
    const invitationUrl = `${webBaseUrl}/bound/accept/${token}`
    await navigator.clipboard.writeText(invitationUrl)
    alert('Seu link de convite foi copiado para a área de transferência')
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Listagem dos clientes
        </Typography>
      </Stack>

      <Paper sx={{ mb: 2, p: 2 }} >
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={2}>
          <Stack direction='row' gap={2}>
            <Button color='secondary' variant='contained' onClick={invite}>Convidar</Button>
            <Button variant='outlined' component={Link} to={'/bounds/new-client'}>Cadastrar</Button>
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <TextField label='Busca' variant='outlined' value={search} onChange={e => setSearch(e.target.value)} size='small' />
            <FormControlLabel control={<Switch checked={onlyActive} onChange={e => setOnlyActive(e.target.checked)} />} label="Apenas ativos" />
          </Stack>
        </Stack>
      </Paper>

      <Paper sx={{ width: '100%', flexGrow: 1 }}>
        <DataGrid
          rows={bounds}
          getRowId={c => c.id}
          columns={getColumns()}
          disableRowSelectionOnClick
          disableColumnMenu
          autoPageSize={true}
          onRowClick={onRowClick}
          loading={loading}
        />
      </Paper>
    </Stack >
  )
}

function getColumns(): GridColDef[] {
  return [
    { field: 'name', headerName: 'Nome', flex: 1, valueGetter: b => b.row.client.name },
    {
      field: 'sessionCount',
      headerName: 'Sessões realizadas',
      align: 'right',
      headerAlign: 'right',
      valueFormatter: a => formatInteger(a.value),
      width: 150
    },
    {
      field: 'nextSessionDate',
      headerName: 'Próxima sessão',
      width: 150,
      valueGetter: a => a.row.nextSession?.start,
      valueFormatter: a => formatDate(a.value),
    },
    {
      field: 'defaultSessionValue',
      headerName: 'Valor padrão',
      align: 'right',
      headerAlign: 'right',
      width: 150,
      valueFormatter: a => formatMoney(a.value),
    },
    {
      field: 'dueAmount',
      headerName: 'Valor a pagar',
      align: 'right',
      headerAlign: 'right',
      width: 150,
      valueFormatter: a => formatMoney(a.value),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem icon={<WhatsApp />} onClick={() => sendWhatsApp(params.row.client.phoneNo)} label='Abrir WhatsApp' disabled={!params.row.client.phoneNo} />,
        <GridActionsCellItem icon={<Email />} onClick={() => sendEmail(params.row.client.email)} label='Enviar Email' disabled={!params.row.client.email} />,
      ]
    },
  ]
}
