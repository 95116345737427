import { ChangeCircleOutlined, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Edit, Email, Schedule, WhatsApp } from '@mui/icons-material'
import { Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { Center } from '../../components/Center'
import { StatBox } from '../../components/StatBox'
import { useErrorHandler } from '../../hooks/error-handler'
import { sendEmail, sendWhatsApp } from '../../utils/contact'
import { formatDate, formatInteger, formatMoney, formatRelativeDate, formatTimeRange } from '../../utils/format'
import { CreateSessionDialog } from './CreateSessionDialog'
import { SessionValueDialog } from './SessionValueDialog'
import { SessionModel, useBoundQuery, useUpdateBoundMutation } from './api'
import { SectionPaper } from '../../components/SectionPaper'

export function Bound() {
  const params = useParams()
  const boundId = params.id!

  const navigate = useNavigate()
  const { handleError } = useErrorHandler()

  const [sessionValueDialogOpen, setSessionValueDialogOpen] = useState(false)
  const [createSessionDialogOpen, setCreateSessionDialogOpen] = useState(false)

  const { data: { bound } = {}, loading, refetch } = useBoundQuery({ id: boundId })

  const [updateBound] = useUpdateBoundMutation()

  const onRowClick = (session: SessionModel) => {
    navigate(`/bounds/${boundId}/session/${session.id}`)
  }

  const onChangeActive = async () => {
    try {
      await updateBound({ variables: { id: boundId, active: !bound?.active } })
      await refetch()
    } catch (e) {
      handleError(e, 'Não foi possível atualizar o status do cliente')
    }
  }

  if (loading) return <Center><CircularProgress /></Center>
  if (!bound) return <Navigate to='/bounds' />

  return (
    <>
      <Stack gap={2}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Detalhes do cliente
        </Typography>
        <SectionPaper title="Dados Básicos">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} lg={10}>
              <TextField label='Nome' variant='outlined' value={bound.client.name ?? ''} InputProps={{ readOnly: true }} fullWidth />
            </Grid>
            <Grid item xs={12} sm={3} lg={2} component={Stack} justifyContent='stretch'>
              <Button variant='outlined' component={Link} to={`/bounds/${boundId}/client`} fullWidth>Editar</Button>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => sendEmail(bound.client.email!)} disabled={!bound.client.email}>
                        <Email />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={bound.client.email ?? ''}
                  inputProps={{ readOnly: true }}
                  label="Email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="phoneNo">Celular/WhatsApp</InputLabel>
                <OutlinedInput
                  id="phoneNo"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => sendWhatsApp(bound.client.phoneNo!)} disabled={!bound.client.phoneNo}>
                        <WhatsApp />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={bound.client.phoneNo ?? ''}
                  inputProps={{ readOnly: true }}
                  label="Celular/WhatsApp"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label='Nascimento' variant='outlined' value={formatDate(bound.client.birthDate)} InputProps={{ readOnly: true }} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label='CPF' variant='outlined' value={bound.client.cpf ?? ''} InputProps={{ readOnly: true }} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label='Contato de Emergência' variant='outlined' value={bound.client.emergencyContactName ?? ''} InputProps={{ readOnly: true }} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="emergencyContactPhoneNo">Celular/WhatsApp</InputLabel>
                <OutlinedInput
                  id="emergencyContactPhoneNo"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => sendWhatsApp(bound.client.emergencyContactPhoneNo!)} disabled={!bound.client.emergencyContactPhoneNo}>
                        <WhatsApp />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={bound.client.emergencyContactPhoneNo ?? ''}
                  inputProps={{ readOnly: true }}
                  label="Celular/WhatsApp"
                />
              </FormControl>
            </Grid>
          </Grid>
        </SectionPaper>

        <SectionPaper title="Configurações">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="defaultSessionValue">Valor da sessão</InputLabel>
                <OutlinedInput
                  id="defaultSessionValue"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setSessionValueDialogOpen(true)} >
                        <Edit />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={formatMoney(bound.defaultSessionValue)}
                  inputProps={{ readOnly: true }}
                  label="Valor da sessão"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="active">Situação</InputLabel>
                <OutlinedInput
                  id="active"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={onChangeActive} >
                        <ChangeCircleOutlined />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={bound.active ? 'Ativo' : 'Inativo'}
                  inputProps={{ readOnly: true }}
                  label="Situação"
                />
              </FormControl>
            </Grid>
          </Grid>
        </SectionPaper>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}><StatBox value={formatInteger(bound.sessionCount ?? 0)} caption='Sessões' variant='success' /></Grid>
          <Grid item xs={12} sm={6}><StatBox value={formatMoney(bound.dueAmount ?? 0)} caption='Valor a pagar' variant='success' /></Grid>
          <Grid item xs={12} sm={6}><StatBox value={formatDate(bound.lastSession?.start) || '-'} caption={`Última sessão ${formatRelativeDate(bound.lastSession?.start)}`} variant='info' /></Grid>
          <Grid item xs={12} sm={6}><StatBox value={formatDate(bound.nextSession?.start) || '-'} caption={`Próxima sessão ${formatRelativeDate(bound.nextSession?.start)}`} variant='info' /></Grid>
        </Grid>

        <SectionPaper title="Ações">
          <Stack direction="row" gap={2}>
            <Button variant='contained' component={Link} to={`/bounds/${boundId}/new-session`} >Nova sessão</Button>
            <Button variant='outlined' onClick={() => setCreateSessionDialogOpen(true)}>Sessões recorrentes</Button>
          </Stack>
        </SectionPaper>

        <Paper sx={{ height: '90vh' }}>
          <DataGrid
            rows={bound.sessions ?? []}
            getRowId={c => c.id}
            columns={getSessionColumns()}
            disableRowSelectionOnClick
            autoPageSize={true}
            onRowClick={e => onRowClick(e.row)}
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{
                  field: 'date',
                  sort: 'desc',
                }]
              }
            }}
          />
        </Paper>
      </Stack>

      <SessionValueDialog
        open={sessionValueDialogOpen}
        onClose={() => setSessionValueDialogOpen(false)}
        onChange={() => refetch()}
        bound={bound}
      />

      <CreateSessionDialog
        open={createSessionDialogOpen}
        onClose={() => setCreateSessionDialogOpen(false)}
        onCreate={() => refetch()}
        bound={bound}
      />
    </>
  )
}

function getSessionColumns(): GridColDef[] {
  return [
    {
      field: 'date',
      headerName: 'Data',
      flex: 1,
      valueGetter: b => b.row.start,
      sortComparator: (v1, v2, row1, row2) => dayjs(row1.value).isBefore(dayjs(row2.value)) ? -1 : 1,
      renderCell: cell => (
        <Tooltip title={dayjs(cell.row.start).fromNow()} arrow>
          <Typography>{dayjs(cell.value).format('DD/MM/YYYY')}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'time',
      headerName: 'Horário',
      flex: 1,
      valueGetter: b => formatTimeRange(b.row.start, b.row.end),
      sortable: false,
    },
    {
      field: 'present',
      headerName: 'Presença',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => dayjs(params.row.start).isAfter(dayjs()) ? <Schedule /> : params.row.present ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />,
    },
    {
      field: 'summary',
      headerName: 'Resumo',
      flex: 1,
      sortable: false,
    },
    {
      field: 'sessionValue',
      headerName: 'Valor',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: a => formatMoney(a.value),
    },
    {
      field: 'paid',
      headerName: 'Pago',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => params.row.paid ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />,
    }
  ]
}
