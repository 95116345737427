import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch } from '@mui/material'
import { Stack } from '@mui/system'
import { useState } from 'react'
import { MoneyField } from '../../components/MoneyField'
import { useErrorHandler } from '../../hooks/error-handler'
import { BoundModel, useUpdateBoundDefaultValueAndSessionsMutation, useUpdateBoundMutation } from './api'

export interface SessionValueDialogProps {
  bound: BoundModel
  open: boolean
  onClose: () => void
  onChange?: (value: number) => void
}

export function SessionValueDialog(props: SessionValueDialogProps) {
  const { handleError } = useErrorHandler()

  const [defaultSessionValue, setDefaultSessionValue] = useState(props.bound.defaultSessionValue)
  const [updateFutureSessions, setUpdateFutureSessions] = useState(false)

  const [updateBound] = useUpdateBoundMutation()
  const [updateBoundAndSessions] = useUpdateBoundDefaultValueAndSessionsMutation()


  const save = async () => {
    try {
      if (updateFutureSessions) {
        await updateBoundAndSessions({ variables: { boundId: props.bound.id, startFrom: new Date(), defaultSessionValue } })
      } else {
        await updateBound({ variables: { id: props.bound.id, defaultSessionValue } })
      }
      props.onChange?.(defaultSessionValue)
      props.onClose()
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} >
      <DialogTitle>Valor da sessão</DialogTitle>
      <DialogContent>
        <Stack gap={2} marginTop={2}>
          <MoneyField label='Novo valor' variant='outlined' value={defaultSessionValue} onChange={setDefaultSessionValue} fullWidth required />
          <FormControlLabel control={<Switch checked={updateFutureSessions} onChange={e => setUpdateFutureSessions(e.target.checked)} />} label="Atualizar sessões futuras" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={save}>Salvar</Button>
        <Button onClick={props.onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  )
}
