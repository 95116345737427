import { CircularProgress, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Center } from '../../components/Center'
import { PersonForm, PersonFormModel } from '../../components/PersonForm'
import { useClientQuery, useCreateClientMutation, useUpdateClientMutation } from './api'

export function Client() {
  const { boundId } = useParams()

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Detalhes do cliente
        </Typography>
      </Stack>

      {boundId ? <EditClient id={boundId} /> : <NewClient />}
    </>
  )
}

interface EditClientProps {
  id: string
}

function NewClient() {
  const navigate = useNavigate()
  const [createClient] = useCreateClientMutation()

  async function create(toCreate: PersonFormModel) {
    const { data } = await createClient({ variables: toCreate })
    return data!.boundCreate.id
  }

  async function afterSave(id: string) {
    navigate(`/bounds/${id}`)
  }

  return <PersonForm onSave={create} afterSave={afterSave} allowChangeEmail></PersonForm>
}

function EditClient({ id }: EditClientProps) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { data, loading } = useClientQuery({ id })
  const [updateClient] = useUpdateClientMutation()

  async function update(data: PersonFormModel) {
    await updateClient({ variables: { ...data, boundId: id } })
    return id
  }

  async function afterSave() {
    navigate(`/bounds/${id}`)
  }

  if (loading) return <Center><CircularProgress /></Center>

  if (!data?.bound.client) {
    enqueueSnackbar({ message: 'Cliente não encontrado!' })
    return <Navigate to='/bounds' />
  }

  return <PersonForm data={data?.bound.client} onSave={update} afterSave={afterSave} allowChangeEmail></PersonForm>
}
