import { Paper, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface SectionPaperProps {
  title: string
  children: ReactNode
}

export function SectionPaper({ title, children }: SectionPaperProps) {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {children}
    </Paper>
  )
}
