export interface UploadFileParams {
  url: string
  file: File
  progressCallback: (progressPercentage: number, finished: boolean) => void
}

export async function uploadFile({ url, file, progressCallback }: UploadFileParams) {
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('PUT', url, true)
    xhr.setRequestHeader('Content-Type', 'application/octet-stream')

    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total)
        progressCallback(percentComplete, false)
      }
    }

    xhr.onload = function () {
      progressCallback(100, true)
      if (xhr.status === 200) {
        resolve()
      } else {
        reject(xhr.responseText)
      }
    }

    xhr.onerror = function () {
      progressCallback(100, true)
      reject()
    }

    xhr.send(file)
  })
}
