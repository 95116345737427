import { gql, useMutation, useQuery } from '@apollo/client'

const invoiceQuery = gql`
  query Invoice($invoiceId: String!) {
    invoice(invoiceId: $invoiceId) {
        id
        value
        invoiceDate
        dueDate
        paymentDate
        paymentMethod
        paid
        bound {
            id
            client {
                name
                email
                phoneNo
                cpf
            }
        }
        sessions {
            id
            start
            sessionValue
            summary
        }
    }
  }
`

const invoiceUpdateMutation = gql`
  mutation InvoiceUpdate($invoiceId: String!, $paymentDate: DateTime, $paymentMethod: String) {
    invoiceUpdate(invoiceId: $invoiceId, data: { paymentDate: $paymentDate, paymentMethod: $paymentMethod }) {
        id
    }
  }
`

const invoiceDeleteMutation = gql`
  mutation InvoiceDelete($invoiceId: String!) {
    invoiceDelete(invoiceId: $invoiceId)
  }
`

export interface InvoiceModel {
  id: string
  value: number
  invoiceDate: Date
  dueDate: Date
  paymentDate?: Date
  paymentMethod?: string
  paid: boolean
  bound: {
    id: string
    client: {
      name: string
      email: string
      phoneNo: string
      cpf: string
    }
  }
  sessions: {
    id: string
    start: Date
    sessionValue: number
    summary: string
  }[]
}

interface InvoiceQueryInput {
  invoiceId: string
}

interface InvoiceQueryOutput {
  invoice: InvoiceModel
}

interface InvoiceUpdateInput {
  invoiceId: string
  paymentDate: Date | null
  paymentMethod: string | null
}

interface InvoiceUpdateOutput {
  id: string
}

interface InvoiceDeleteInput {
  invoiceId: string
}

export const useInvoiceQuery = (variables?: InvoiceQueryInput) => useQuery<InvoiceQueryOutput, InvoiceQueryInput>(invoiceQuery, { variables })
export const useInvoiceUpdateMutation = (variables?: InvoiceUpdateInput) => useMutation<InvoiceUpdateOutput, InvoiceUpdateInput>(invoiceUpdateMutation, { variables })
export const useInvoiceDeleteMutation = (variables?: InvoiceDeleteInput) => useMutation<Boolean, InvoiceDeleteInput>(invoiceDeleteMutation, { variables })
