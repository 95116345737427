import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

export function useSearchedData<T>(data: T[], search: string) {
  const [found, setFound] = useState(data)
  const [searchToApply] = useDebounce(search, 300)

  useEffect(() => {
    setFound(searchOn(data, searchToApply))
  }, [data, searchToApply])

  return found
}

function searchOn<T>(data: T[], search: string): T[] {
  if (!data) return []
  if (!search) return data

  const tokens = standardize(search).split(' ')
  return data
    .map(item => {
      const standardized = standardize(valuesToString(item))
      const scoreValue = score(standardized, tokens)
      return { item, standardized, score: scoreValue }
    })
    .filter(a => a.score > 0)
    .sort((a, b) => b.score - a.score)
    .map(a => a.item)
}

function standardize(text: string): string {
  return text
    .toLowerCase()
    .replace(/[áàâãä]/g, 'a')
    .replace(/[éèêë]/g, 'e')
    .replace(/[íìîï]/g, 'i')
    .replace(/[óòôõö]/g, 'o')
    .replace(/[úùûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/[ \n]+/g, ' ')
    .trim()
}

function valuesToString(data: any): string {
  if (typeof data === 'undefined') {
    return ''
  } else if (data === null) {
    return ''
  } else if (typeof data === 'string') {
    return data
  } else if (Array.isArray(data)) {
    return data.map(a => valuesToString(a)).join(' ')
  } else if (typeof data === 'object') {
    return valuesToString(Object.values(data))
  } else {
    return data.toString()
  }
}

function score(value: string, tokens: string[]): number {
  let score = 0
  for (const token of tokens) {
    if (value.includes(token))
      score++
  }
  return score
}
