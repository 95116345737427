import { gql, useMutation, useQuery } from '@apollo/client'

const therapistQuery = gql`
  query Therapist {
    therapist {
        id
        license
        specializations
        biography
        site
        defaultSessionValue
        letterhead
        letterheadUrl
    }
  }
`

const therapistCreateMutation = gql`
  mutation TherapistCreate($defaultSessionValue: Int!, $site: String, $biography: String, $specializations: [String!], $license: String!, $letterhead: String) {
    therapistCreate(
        data: {
            defaultSessionValue: $defaultSessionValue
            site: $site
            biography: $biography
            specializations: $specializations
            license: $license
            letterhead: $letterhead
        }
    ) {
        id
    }
  }
`

const therapistUpdateMutation = gql`
  mutation TherapistUpdate($defaultSessionValue: Int!, $site: String, $biography: String, $specializations: [String!], $license: String!, $letterhead: String) {
    therapistUpdate(
        data: {
            defaultSessionValue: $defaultSessionValue
            site: $site
            biography: $biography
            specializations: $specializations
            license: $license
            letterhead: $letterhead
        }
    ) {
        id
    }
  }
`

interface TherapistQueryOutput {
  id: string
  license: string
  specializations?: string[]
  biography?: string
  site?: string
  defaultSessionValue: number
  letterhead?: string
  letterheadUrl?: string
}

interface TherapistCreateInput {
  license: string
  specializations?: string[]
  biography?: string
  site?: string
  defaultSessionValue: number
}

interface TherapistCreateOutput {
  id: string
}

interface TherapistUpdateInput {
  license: string
  specializations?: string[]
  biography?: string
  site?: string
  defaultSessionValue: number
}

interface TherapistUpdateOutput {
  id: string
}

export const useTherapistQuery = () => useQuery<{ therapist: TherapistQueryOutput }>(therapistQuery)
export const useTherapistCreateMutation = (variables?: TherapistCreateInput) => useMutation<{ therapistCreate: TherapistCreateOutput }, TherapistCreateInput>(therapistCreateMutation, { variables })
export const useTherapistUpdateMutation = (variables?: TherapistUpdateInput) => useMutation<{ therapistUpdate: TherapistUpdateOutput }, TherapistUpdateInput>(therapistUpdateMutation, { variables })
