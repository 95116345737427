import { InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

export interface MoneyFieldProps {
  id?: string
  variant?: 'standard' | 'outlined' | 'filled'
  label?: string
  value: number
  required?: boolean
  fullWidth?: boolean
  onChange: (value: number) => void
}

export function MoneyField(props: MoneyFieldProps) {
  const { value, onChange, ...other } = props

  const [inputValue, setInputValue] = useState(intToString(value))

  useEffect(() => {
    if (value !== stringToInt(inputValue)) {
      setInputValue(intToString(value))
    }
    // inputValue cannot be added to dependency list, otherwise each call on onInputChange will
    // trigger this effect and re-set input value again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInputChange = (newValue: string) => {
    if (!newValue.match(/^[0-9]{0,7}(,([0-9]{0,2}))?$/)) return

    setInputValue(newValue)
    onChange(stringToInt(newValue))
  }

  return (
    <TextField {...other}
      type='text'
      value={inputValue}
      onChange={e => onInputChange(e.target.value)}
      InputProps={{
        startAdornment: <InputAdornment position="start">R$</InputAdornment>
      }}
    />
  )
}

function intToString(value: number) {
  return (value / 100).toFixed(2).replace('.', ',')
}

function stringToInt(value: string) {
  const parsedValue = parseFloat(value.replace(',', '.'))
  if (isNaN(parsedValue)) return 0

  return Math.round(parsedValue * 100)
}
