import { gql, useLazyQuery } from '@apollo/client'

const uploadUrlQuery = gql`
  query UploadUrl($purpose: String!) {
    uploadUrl(purpose: $purpose) {
        url
        fileName
    }
  }
`

interface UploadUrlInput {
  purpose: string
}

interface UploadUrlOutput {
  url: string
  fileName: string
}

export const useLazyUploadUrlQuery = (variables?: UploadUrlInput) => useLazyQuery<{ uploadUrl: UploadUrlOutput }, UploadUrlInput>(uploadUrlQuery, { variables })
