import { gql, useLazyQuery, useQuery } from '@apollo/client'

const invitationTokenQuery = gql`
  query BoundInvitationToken {
    boundInvitationToken
  }
`

const defaultBoundsQuery = gql`
query Bounds($onlyActive: Boolean) {
    bounds (filter: {onlyActive: $onlyActive}) {
      id
      defaultSessionValue
      sessionCount
      dueAmount
      nextSession {start}
      client {name email phoneNo}
    }
  }
`
export interface BoundModel {
  id: string
  defaultSessionValue: number
  sessionCount: number
  dueAmount: number
  nextSession: { start: string }
  client: {
    name: string
    email: string
    phoneNo: string
  }
}

interface InvitationTokenOutput {
  boundInvitationToken: string
}

interface BoundQueryInput {
  onlyActive?: boolean
}

interface BoundQueryOutput {
  bounds: BoundModel[]
}

export const useInvitationTokenLazyQuery = () => useLazyQuery<InvitationTokenOutput>(invitationTokenQuery)
export const useBoundQuery = (variables?: BoundQueryInput) => useQuery<BoundQueryOutput, BoundQueryInput>(defaultBoundsQuery, { variables })
