import { ProcessedEvent } from '@aldabil/react-scheduler/types'
import { SessionModel } from './api'

export interface SessionEvent extends ProcessedEvent {
  session: SessionModel
}

export const sessionToEvent = (session: SessionModel): SessionEvent => ({
  event_id: session.id,
  color: sessionColor(session),
  title: session.bound.client.name,
  start: new Date(session.start),
  end: new Date(session.end),
  session,
})

function sessionColor(session: SessionModel) {
  if (session.paid) return 'green'
  if (session.present) return 'blue'
  return 'gray'
}
