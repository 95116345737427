import { gql, useMutation, useQuery } from '@apollo/client'

const invoicesQuery = gql`
  query Invoices($paid: Boolean) {
    invoices(criteria: {paid: $paid}) {
      id
      bound {
        client {
          name
          phoneNo
        }
      }
      sessions {
        id
        start
      }
      value
      dueDate
      paymentDate
      paid
    }
  }
`

const invoiceDeleteMutation = gql`
  mutation InvoiceDelete($invoiceId: String!) {
    invoiceDelete(invoiceId: $invoiceId)
  }
`

export interface InvoiceModel {
  id: string
  bound: {
    client: {
      name: string
      phoneNo: string
    }
  }
  sessions: {
    id: string
    start: Date
  }[]
  value: number
  dueDate: Date
  paymentDate: Date
  paid: boolean
}

interface InvoicesInput {
  paid?: boolean
}

interface InvoicesOutput {
  invoices: InvoiceModel[]
}

interface InvoiceDeleteInput {
  invoiceId: string
}

export const useInvoicesQuery = (variables?: InvoicesInput) => useQuery<InvoicesOutput, InvoicesInput>(invoicesQuery, { variables })
export const useInvoiceDeleteMutation = (variables?: InvoiceDeleteInput) => useMutation<Boolean, InvoiceDeleteInput>(invoiceDeleteMutation, { variables })
