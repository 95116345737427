import { Box, BoxProps } from '@mui/material'
import React from 'react'

export const Center = React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} display='flex' justifyContent='center' alignItems='center' width='100%' height='100%' {...props}>
      {children}
    </Box>
  )
})
