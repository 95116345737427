import { useState } from 'react'
import { Card, CardHeader, CardContent, CardActions, Divider, Stack, IconButton, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import FileSaver from 'file-saver'
import { gql, useLazyQuery } from '@apollo/client'
import { useErrorHandler } from '../hooks/error-handler'
import { Center } from './Center'

export interface ReceiptProps {
  id: string
  therapist?: {
    name: string
    cpf?: string
  }
  paymentDate?: Date
  paymentMethod?: string
  value: number
  client: {
    name: string
    cpf?: string
  }
}

export function Receipt(props: ReceiptProps) {
  const { handleError } = useErrorHandler()

  const [fetchPdf] = useLazyQuery(gql`query invoiceReceipt($invoiceId: String!) {
    invoiceReceipt(invoiceId: $invoiceId)
  }`, { variables: { invoiceId: props.id } })

  const [downloading, setDownloading] = useState(false)

  const handleDownloadPDF = async () => {
    try {
      setDownloading(true)
      const pdf = await fetchPdf()

      const b64toBlob =
        await fetch(`data:application/pdf;base64,${pdf.data.invoiceReceipt}`).then(res => res.blob())

      FileSaver.saveAs(b64toBlob, `${props.id}.pdf`)
    } catch (error) {
      handleError(error, 'Erro ao fazer download do PDF')
    } finally {
      setDownloading(false)
    }
  }

  return (
    <Center>
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              <img src='/favicon.ico' alt='psicare' style={{ width: '100%' }} />
            </Avatar>
          }
          title={
            <Typography gutterBottom variant="body1" component="div">
              Comprovante de Pagamento
            </Typography>
          }
        />
        <CardContent>
          <Divider />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography gutterBottom variant="subtitle1" component="div">
              Valor
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
              {props.value / 100}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography gutterBottom variant="subtitle1" component="div">
              Tipo de Transferência
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
              {props.paymentMethod}
            </Typography>
          </Stack>
          <Typography gutterBottom variant="h6">
            Destino
          </Typography>
          <Divider />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography gutterBottom variant="subtitle1" component="div">
              Nome
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
              {props.client.name}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography gutterBottom variant="subtitle1" component="div">
              CPF
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
              {props.client.cpf}
            </Typography>
          </Stack>
          <Typography gutterBottom variant="h6" component="div">
            Identificador
          </Typography>
          <Divider />
          <Typography gutterBottom variant="subtitle2" component="div">
            {props.id}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="share" onClick={handleDownloadPDF} disabled={downloading}>
            <CloudDownloadIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Center>
  )
}
