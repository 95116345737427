export function sendWhatsApp(phoneNo?: string, text?: string) {
  if (!phoneNo) return

  let url = `https://api.whatsapp.com/send?phone=${normalizePhoneNumber(phoneNo)}`
  if (text)
    url += `&text=${encodeURIComponent(text ?? '')}`

  window.open(url)
}

export function sendEmail(email?: string) {
  if (!email) return
  window.open(`mailto:${email}`)
}

function normalizePhoneNumber(phoneNo: string) {
  return phoneNo.replace(/[^\d]/g, '')
}
