import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

const summaryQuery = gql`
  query Sessions($thisMonthStartFrom: DateTime!, $thisMonthStartTo: DateTime!, $lastMonthStartFrom: DateTime!, $lastMonthStartTo: DateTime!) {
    thisMonthSessions: sessionsToTherapist(query: {criteria: {start: {from: $thisMonthStartFrom, to: $thisMonthStartTo}}}) {
      start
      present
      paid
      sessionValue
    }
    lastMonthSessions: sessionsToTherapist(query: {criteria: {start: {from: $lastMonthStartFrom, to: $lastMonthStartTo}}}) {
      start
      present
      paid
      sessionValue
    }
  }
`

const clientSessionsQuery = gql`
  query Sessions($fromDate: DateTime!) {
    sessionsToClient(
      query: {
        criteria: {start: {from: $fromDate}}
        order: [{by: start, direction: ASC}]
      }
    ) {
      id
      start
      bound {
        therapist {
          license
          user {
            id
            name
            email
            phoneNo
          }
        }
      }
    }
  }
`

export interface SummarySessionModel {
  start: Date
  present: boolean
  paid: boolean
  sessionValue: number
}

interface SummarySessionInput {
  thisMonthStartFrom: Date
  thisMonthStartTo: Date
  lastMonthStartFrom: Date
  lastMonthStartTo: Date
}

interface SummarySessionOutput {
  thisMonthSessions: SummarySessionModel[]
  lastMonthSessions: SummarySessionModel[]
}

export interface ClienteSessionModel {
  id: string
  start: Date
  bound: {
    therapist: {
      license: string
      user: {
        id: string
        name: string
        email: string
        phoneNo: string
      }
    }
  }
}

interface ClientSessionsQueryInput {
  fromDate: Date
}

interface ClientSessionsQueryOutput {
  sessionsToClient: ClienteSessionModel[]
}

const useSummarySessionsQuery = (variables?: SummarySessionInput) => useQuery<SummarySessionOutput, SummarySessionInput>(summaryQuery, { variables })

export const useSummary = () => {

  const startOfMonth = dayjs().startOf('month')

  const { data } = useSummarySessionsQuery({
    thisMonthStartFrom: startOfMonth.toDate(),
    thisMonthStartTo: startOfMonth.endOf('month').toDate(),
    lastMonthStartFrom: startOfMonth.subtract(1, 'month').toDate(),
    lastMonthStartTo: startOfMonth.toDate(),
  })

  const thisMonthSessions = data?.thisMonthSessions ?? []
  const lastMonthSessions = data?.lastMonthSessions ?? []

  const sum = (a: number, b: number) => a + b

  return {
    sessionCountLastMonth: lastMonthSessions.filter(a => a.present).length,
    sessionCountThisMonth: thisMonthSessions.filter(a => a.present).length,
    sessionCountProjection: thisMonthSessions.filter(a => a.present || dayjs(a.start).isAfter(dayjs())).length,
    revenueLastMonth: lastMonthSessions.filter(a => a.present || a.paid).map(a => a.sessionValue).reduce(sum, 0),
    revenueThisMonth: thisMonthSessions.filter(a => a.present || a.paid).map(a => a.sessionValue).reduce(sum, 0),
    revenueProjection: thisMonthSessions.filter(a => a.present || a.paid || dayjs(a.start).isAfter(dayjs())).map(a => a.sessionValue).reduce(sum, 0),
  }
}

export const useClientSessionsQuery = (variables?: ClientSessionsQueryInput) => useQuery<ClientSessionsQueryOutput, ClientSessionsQueryInput>(clientSessionsQuery, { variables })
