import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ReactNode } from 'react'

export interface ConfirmationDialogProps {
  title: string
  children: ReactNode
  open: boolean
  onConfirm: () => Promise<void> | void
  onCancel?: () => Promise<void> | void
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.onCancel} >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onConfirm} autoFocus>Sim</Button>
        <Button onClick={props.onCancel}>Não</Button>
      </DialogActions>
    </Dialog>
  )
}
