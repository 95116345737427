import { CloudDone, Email, Launch, Payment, WhatsApp } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Card, CardContent, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { DatePicker, TimeField } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { Center } from '../../components/Center'
import { ConfirmationDialog } from '../../components/ConfirmationDialog'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { sendEmail, sendWhatsApp } from '../../utils/contact'
import { concatDateTime } from '../../utils/date'
import { formatDate } from '../../utils/format'
import { BoundModel, OtherSessionModel, useBoundLazyQuery, useCreateSessionMutation, useDeleteSessionMutation, useSessionLazyQuery, useUpdateSessionMutation } from './api'
import { MoneyField } from '../../components/MoneyField'
import { SectionPaper } from '../../components/SectionPaper'
import { useDebouncedCallback } from 'use-debounce'

export function Session() {
  const params = useParams()
  const boundId = params.boundId!
  const sessionId = params.sessionId

  const navigate = useNavigate()
  const location = useLocation()
  const { handleError } = useErrorHandler()

  const [bound, setBound] = useState<BoundModel | null>(null)
  const [date, setDate] = useState<Dayjs | null>(null)
  const [start, setStart] = useState<Dayjs | null>(null)
  const [end, setEnd] = useState<Dayjs | null>(null)
  const [summary, setSummary] = useState('')
  const [sessionValue, setSessionValue] = useState(0)
  const [present, setPresent] = useState(false)
  const [paid, setPaid] = useState(false)
  const [notes, setNotes] = useState('')
  const [editing, setEditing] = useState(false)

  const [notesHeight, setNotesHeight] = useState(0)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const [fetchSession, { data, loading }] = useSessionLazyQuery()
  const [fetchBound] = useBoundLazyQuery({ id: boundId })

  const [createSession] = useCreateSessionMutation()
  const [updateSession] = useUpdateSessionMutation()
  const [deleteSession] = useDeleteSessionMutation()

  useEffect(() => {
    if (sessionId) {
      fetchSession({ variables: { id: sessionId } })
    } else {
      fetchBound()
        .then(({ data }) => {
          setBound(data?.bound ?? null)
          setSessionValue(data?.bound?.defaultSessionValue ?? 0)
        })
    }
  }, [sessionId, fetchSession, fetchBound])

  useEffect(() => {
    if (!data) return

    const { sessionToTherapist: session } = data
    setDate(dayjs(session?.start))
    setStart(dayjs(session?.start) ?? dayjs().startOf('hour').add(1, 'hour'))
    setEnd(dayjs(session?.end) ?? dayjs().startOf('hour').add(1, 'hour').add(50, 'minutes'))
    setSummary(session.summary ?? '')
    setSessionValue(session.sessionValue ?? session.bound?.defaultSessionValue ?? 0)
    setPresent(session.present ?? false)
    setPaid(session.paid ?? false)
    setNotes(session.notes ?? '')
    setBound(session.bound ?? null)
  }, [data])

  const save = async () => {
    try {
      if (!date || !dayjs(date, 'DD-MM-YYYY', true).isValid()) throw new ExpectedError('Informe a data')
      if (!start || !dayjs(start, 'HH:MM', true).isValid()) throw new ExpectedError('Início inválido')
      if (!end || !dayjs(end, 'HH:MM', true).isValid()) throw new ExpectedError('Fim inválido')
      if (start >= end) throw new ExpectedError('Início deve ser menor que o fim')

      const startDateTime = concatDateTime(date, start).toDate()
      const endDateTime = concatDateTime(date, end).toDate()

      const body = {
        start: startDateTime,
        end: endDateTime,
        summary,
        sessionValue,
        present,
        paid,
        notes,
      }

      if (sessionId) {
        const { data } = await updateSession({ variables: { id: sessionId, ...body } })
        return data?.sessionUpdate.id
      } else {
        const { data } = await createSession({ variables: { boundId: boundId, ...body } })
        return data?.sessionCreate.id
      }
    } catch (e) {
      handleError(e, 'Não foi possível salvar a sessão')
    }
    return false
  }

  const debouncedSave = useDebouncedCallback(async () => {
    const sessionId = await save()

    if (sessionId) {
      setEditing(false)
    }

  }, 5000)

  useEffect(() => {
    if (!sessionId) return

    setEditing(true)
    debouncedSave()
  }, [sessionId, notes, debouncedSave])

  const saveAndNavigateToSessionPage = async () => {
    const sessionId = await save()
    if (sessionId) {
      navigate(`/bounds/${boundId}/session/${sessionId}`, { state: { from: location }, replace: true })
    }

    return sessionId
  }

  const back = () => {
    navigate(-1)
  }

  const saveAndBack = async () => {
    if (await save())
      back()
  }

  const deleteAndBack = async () => {
    if (!sessionId) return

    try {
      await deleteSession({ variables: { id: sessionId } })
      back()
    } catch (e) {
      handleError(e, 'Não foi possível excluir a sessão')
    }
  }

  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node != null) {
      new ResizeObserver(entries => {
        const height = node.getBoundingClientRect().height
        setNotesHeight(height)
      }).observe(node)
    }
  }, [])

  if (loading) return <Center> <CircularProgress /> </Center>

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1} mb={2} justifyContent='space-between'>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#9575CD' }}>
          Detalhes da Sessão
        </Typography>
      </Stack>

      <SectionPaper title="Dados Básicos">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="client">Cliente</InputLabel>
              <OutlinedInput
                id="client"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => navigate(`/bounds/${bound?.id}`)} disabled={!bound}>
                      <Launch />
                    </IconButton>
                    <IconButton edge="end" onClick={() => sendWhatsApp(bound?.client.phoneNo)} disabled={!bound?.client.phoneNo}>
                      <WhatsApp />
                    </IconButton>
                    <IconButton edge="end" onClick={() => sendEmail(bound?.client.email)} disabled={!bound?.client.email}>
                      <Email />
                    </IconButton>
                  </InputAdornment>
                }
                value={bound?.client?.name ?? ''}
                inputProps={{ readOnly: true }}
                label="Cliente"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <DatePicker label="Data" value={date} onChange={setDate} sx={{ width: '100%' }} views={['day', 'month', 'year']} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TimeField label="Início" value={start} onChange={setStart} ampm={false} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TimeField label="Fim" value={end} onChange={setEnd} ampm={false} sx={{ width: '100%' }} />
          </Grid>

          <Grid item xs={12} md={4}>
            <MoneyField label="Valor da sessão" value={sessionValue} onChange={setSessionValue} fullWidth />
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormControlLabel control={<Switch checked={present} onChange={e => setPresent(e.target.checked)} />} label="Presença" />
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack direction='row' alignItems='center'>
              <FormControlLabel control={<Switch checked={paid} onChange={e => setPaid(e.target.checked)} disabled={!!data?.sessionToTherapist.invoice} />} label="Pago" />
              {data?.sessionToTherapist.invoice ?
                <Tooltip arrow title={data.sessionToTherapist.invoice.paymentDate ? `Pago em ${formatDate(data.sessionToTherapist.invoice.paymentDate)}` : `Cobrança com vencimento em ${formatDate(data.sessionToTherapist.invoice.dueDate)}`}>
                  <IconButton component={Link} to={`/invoices/${data.sessionToTherapist.invoice.id}`}><Payment /></IconButton>
                </Tooltip>
                : null}
            </Stack>
          </Grid>
        </Grid>
      </SectionPaper>

      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item xs={12} md={8}>
          <Stack gap={2} component={Paper} flexGrow={2} padding={2} ref={measuredRef}>
            <Typography variant="h6" gutterBottom>
              Prontuário
            </Typography>
            <TextField label="Resumo" value={summary} onChange={e => setSummary(e.target.value)} fullWidth />

            <TextField
              label={
                <Box display="flex" alignItems="center">
                  Notas
                  {editing ? (
                    <CircularProgress size={20} color="inherit" style={{ marginLeft: 8 }} />
                  ) : sessionId ? (
                    <CloudDone style={{ marginLeft: 8 }} />
                  ) : null}
                </Box>
              }
              multiline
              rows={20}
              value={notes}
              onChange={e => setNotes(e.target.value)}
              fullWidth
            />


          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box height={notesHeight}>
            <SessionHistory sessions={bound?.sessions ?? []} currentSessionId={sessionId} boundId={boundId!} />
          </Box>
        </Grid>
      </Grid>

      <Stack sx={{ mt: 2 }} gap={2} direction="row" justifyContent="space-between">
        <ButtonGroup variant="outlined">
          {sessionId ? (
            <>
              <Button onClick={saveAndBack}>Salvar e voltar</Button>
            </>
          ) : (
            <>
              <Button variant="contained" onClick={saveAndNavigateToSessionPage}>Salvar</Button>
              <Button onClick={back}>Voltar</Button>
            </>
          )}
        </ButtonGroup>

        {sessionId && (
          <Button color="error" onClick={() => setDeleteDialogOpen(true)}>Excluir</Button>
        )}
      </Stack>

      <ConfirmationDialog title='Confirmação' open={deleteDialogOpen} onConfirm={deleteAndBack} onCancel={() => setDeleteDialogOpen(false)}>
        <p>Deseja mesmo excluir essa sessão?</p>
        <p>Cliente: <strong>{bound?.client.name}</strong></p>
        <p>Data: <strong>{formatDate(start)}</strong></p>
      </ConfirmationDialog>
    </Box>
  )
}

function SessionHistory({ sessions, currentSessionId, boundId }: { sessions: OtherSessionModel[], currentSessionId?: string, boundId: string }) {
  const [relevantSessions, setRelevantSessions] = useState<OtherSessionModel[]>([])
  const [openSessions, setOpenSessions] = useState<OtherSessionModel[]>([])
  const [qtyTotal, setQtyTotal] = useState(0)
  const [qtyToShow, setQtyToShow] = useState(3)

  useEffect(() => {
    const data = sessions
      .filter(a => a.id !== currentSessionId)
      .filter(a => a.notes || a.summary)
      .sort((a, b) => dayjs(a.start).isBefore(dayjs(b.start)) ? 1 : -1)

    setRelevantSessions(data)
    setQtyTotal(data.length)
  }, [sessions, currentSessionId])

  useEffect(() => {
    setOpenSessions(relevantSessions.slice(0, qtyToShow))
  }, [relevantSessions, qtyToShow])

  if (relevantSessions.length < 1) return <Center>Sem histórico de sessões</Center>

  return (
    <SectionPaper title="Histórico de Sessões">
      <Stack gap={2}>
        {openSessions.map(session => (
          <Card key={session.id}>
            <CardContent>
              <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                {dayjs(session.start).format('DD/MM/YYYY')}
                <IconButton component={Link} to={`/bounds/${boundId}/session/${session.id}`} size='small'><Launch fontSize='small' /></IconButton>
              </Typography>
              <Typography gutterBottom variant="subtitle1" component="div">
                {session.summary}
              </Typography>
              <Typography variant="body2" color="text.secondary" component='pre' whiteSpace='pre-wrap'>
                {session.notes}
              </Typography>
            </CardContent>
          </Card>
        ))}
        {qtyToShow < qtyTotal && <Button onClick={() => setQtyToShow(curr => curr + 3)} sx={{ mt: 4 }}>Mostrar mais</Button>}
      </Stack>
    </SectionPaper>
  )
}
