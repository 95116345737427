import { Delete, Preview } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardContent, CircularProgress, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { uploadFile } from '../../utils/file-upload'
import { renderImage } from '../../utils/image-render'
import { Center } from '../Center'
import { useLazyUploadUrlQuery } from './api'
import noImage from './no-image.svg'

export interface ImageUploadParams {
  label: string
  purpose: string
  description: string
  dimensions: {
    width: number
    height: number
  }
  initialPreview?: string
  onChange: (fileName: string | null) => void
}

export function ImageUploader({ label, initialPreview, purpose, onChange, ...props }: ImageUploadParams) {
  const [previewImage, setPreviewImage] = useState<string | undefined>(initialPreview || undefined)
  const [fullPreview, setFullPreview] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)

  const { handleError } = useErrorHandler()
  const fileInput = useRef<HTMLInputElement>(null)
  const [uploadUrl] = useLazyUploadUrlQuery({ purpose })

  const uploadImage = async (file: File) => {
    const { data } = await uploadUrl()
    if (!data?.uploadUrl?.url || !data?.uploadUrl?.fileName)
      throw new ExpectedError('Não foi possível fazer upload do arquivo')

    const { url, fileName } = data.uploadUrl
    setUploadProgress(0)

    await uploadFile({
      url,
      file,
      progressCallback(progressPercentage, finished) {
        if (finished) return setUploadProgress(null)

        setUploadProgress(progressPercentage)
      },
    })

    return fileName
  }

  const handleImageChange = async (file?: File) => {
    if (!file) return

    try {
      const renderImagePromise = renderImage(file)
      const fileName = await uploadImage(file)
      setPreviewImage(await renderImagePromise)
      onChange(fileName)
    } catch (e) {
      if (fileInput.current)
        fileInput.current.value = ''
      handleError(e)
    }
  }

  const handleClearImage = () => {
    setPreviewImage(undefined)
    if (fileInput.current)
      fileInput.current.value = ''
    onChange(null)
  }

  const cardCover = () => {
    if (uploadProgress !== null) {
      return (
        <Box height='100%'>
          <Center>
            <CircularProgress variant='determinate' value={uploadProgress * 100} />
          </Center>
        </Box>
      )
    }

    return (
      <img src={previewImage ?? noImage} alt={label} width='100%' />
    )
  }

  const cardDescription = () => (
    <>
      <Typography variant="h6">{label}</Typography>
      <Typography variant="body2" color="text.secondary">
        {props.description}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Dimensões: {props.dimensions.width}x{props.dimensions.height}
      </Typography>
    </>
  )

  const cardActions = () => (
    <CardActions>
      <label>
        <input type="file" accept="image/*" hidden ref={fileInput} onChange={e => handleImageChange(e.target.files?.[0])} />
        <Button size="small" variant='contained' component='span'>Escolher imagem</Button>
      </label>
      {previewImage && (
        <>
          <Tooltip title="Vizualizar">
            <IconButton size="small" onClick={() => setFullPreview(true)} sx={{ ml: 1 }}><Preview /></IconButton>
          </Tooltip>
          <Tooltip title="Limpar">
            <IconButton size="small" onClick={handleClearImage}><Delete /></IconButton>
          </Tooltip>
        </>
      )}
    </CardActions>
  )

  const previewModal = () => (
    <Modal open={fullPreview} onClose={() => setFullPreview(false)}>
      <Center onClick={() => setFullPreview(false)}>
        <img src={previewImage} alt={label} style={{ maxWidth: '80vw', maxHeight: '80vh' }} />
      </Center>
    </Modal>
  )

  return (
    <>
      <Card sx={{ maxWidth: 250 }}>
        <CardContent>
          {cardCover()}
          {cardDescription()}
        </CardContent>
        {cardActions()}
      </Card>
      {previewModal()}
    </>
  )
}
