import { KeyboardArrowDown, KeyboardArrowUp, Launch } from '@mui/icons-material'
import { Button, Checkbox, CircularProgress, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { StatBox } from '../../components/StatBox'
import { formatDate, formatInteger, formatMoney, formatTimeRange } from '../../utils/format'
import { SessionModel, useInvoiceCreate, useSessionsQuery } from './api'
import { Center } from '../../components/Center'

interface SelectableSession extends SessionModel {
  selected: boolean
}

interface Bound {
  id: string
  name: string
  phoneNo: string
  sessionCount: number
  due: number
  sessions: SelectableSession[]
}

export function NewInvoice() {
  const { enqueueSnackbar } = useSnackbar()
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs().startOf('month'))
  const [dueDate, setDueDate] = useState<Dayjs | null>(dayjs().startOf('month').add(7, 'day'))
  const [generating, setGenerating] = useState(false)

  const { data, loading, refetch } = useSessionsQuery({ startTo: (toDate ?? dayjs()).toDate() })
  const [bounds, setBounds] = useState<Bound[]>([])

  const createInvoices = useInvoiceCreate()

  useEffect(() => {
    if (!data) {
      setBounds([])
      return
    }

    const sortedSessions = _.sortBy(data.sessionsToTherapist, a => a.start)
    const groupedSessions = _.groupBy(sortedSessions, a => a.bound.id)
    const boundList = _.map(groupedSessions, (sessions, boundId) => ({
      id: boundId,
      name: sessions[0].bound.client.name,
      phoneNo: sessions[0].bound.client.phoneNo,
      sessionCount: sessions.length,
      due: sessions.reduce((acc, curr) => acc + curr.sessionValue, 0),
      sessions: sessions.map(a => ({ ...a, selected: false } as SelectableSession)),
    }))
    const sortedBoundList = _.sortBy(boundList, a => a.name)

    setBounds(sortedBoundList)
  }, [data, toDate])

  const toggleSession = (sessionId: string) => {
    const newBounds = _.cloneDeep(bounds)

    const session = newBounds.flatMap(a => a.sessions).find(a => a.id === sessionId)
    if (!session) return

    session.selected = !session.selected
    setBounds(newBounds)
  }

  const toggleBound = (boundId: string) => {
    const newBounds = _.cloneDeep(bounds)

    const sessions = newBounds.flatMap(a => a.sessions).filter(a => a.bound.id === boundId)
    const newValue = sessions.filter(a => a.selected).length < (sessions.length / 2)

    sessions.forEach(a => a.selected = newValue)
    setBounds(newBounds)
  }

  const toggleAll = () => {
    const newBounds = _.cloneDeep(bounds)

    const sessions = newBounds.flatMap(a => a.sessions)
    const newValue = sessions.filter(a => a.selected).length < (sessions.length / 2)

    sessions.forEach(a => a.selected = newValue)
    setBounds(newBounds)
  }

  const generate = async () => {
    if (!dueDate) {
      enqueueSnackbar({ message: 'Data de vencimento inválida' })
      return
    }

    setGenerating(true)

    const invoices = bounds
      .map(({ sessions, ...boundData }) => ({ ...boundData, sessions: sessions.filter(a => a.selected) }))
      .filter(a => a.sessions.length > 0)
      .map(a => ({
        boundId: a.id,
        sessionIds: a.sessions.map(b => b.id),
        invoiceDate: new Date(),
        dueDate: dueDate.toDate(),
      }))

    await createInvoices(invoices)
    refetch()

    setGenerating(false)
  }

  return (
    <Stack gap={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
        <StatBox value={formatMoney(bounds.flatMap(a => a.sessions).filter(a => a.selected).reduce((acc, curr) => acc + curr.sessionValue, 0))} caption='Valor selecionado' variant='success' />
        <StatBox value={formatInteger(bounds.flatMap(a => a.sessions).filter(a => a.selected).length)} caption='Sessões selecionado' variant='success' />
      </Stack>
      <Stack component={Paper} sx={{ p: 2 }} direction='row' gap={2} alignItems='center'>
        <DatePicker label="Data limite" value={toDate} onChange={setToDate} />
        <DatePicker label="Vencimento" value={dueDate} onChange={setDueDate} />
        <Button variant='contained' disabled={!bounds.flatMap(a => a.sessions).some(a => a.selected) || generating} onClick={generate}>Gerar</Button>
      </Stack>

      {loading ?
        <Center><CircularProgress /></Center> :
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={1}>
                  <Checkbox
                    checked={bounds.some(bound => bound.sessions.every(a => a.selected))}
                    indeterminate={bounds.some(a => a.sessions.some(b => b.selected)) && bounds.some(a => a.sessions.some(b => !b.selected))}
                    onChange={toggleAll}
                    size='small' />
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Sessões</TableCell>
                <TableCell align="right">Valor total</TableCell>
                <TableCell align="right">Valor a cobrar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bounds.map(bound => <Row key={bound.id} bound={bound} toggleBound={toggleBound} toggleSession={toggleSession} />)}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Stack>
  )
}

function Row({ bound, toggleBound, toggleSession }: { bound: Bound, toggleBound: (boundId: string) => void, toggleSession: (sessionId: string) => void }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell width={1}>
          <Stack direction='row'>
            <Checkbox
              checked={bound.sessions.every(a => a.selected)}
              indeterminate={bound.sessions.some(a => a.selected) && bound.sessions.some(a => !a.selected)}
              onChange={() => toggleBound(bound.id)}
              size='small' />
            <IconButton size="small" onClick={() => setOpen(curr => !curr)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography component='span'>{bound.name}</Typography>
          <IconButton component={Link} to={`/bounds/${bound.id}`}><Launch /></IconButton>
        </TableCell>
        <TableCell align="right">{formatInteger(bound.sessionCount)}</TableCell>
        <TableCell align="right">{formatMoney(bound.due)}</TableCell>
        <TableCell align="right">{formatMoney(bound.sessions.filter(a => a.selected).reduce((acc, curr) => acc + curr.sessionValue, 0))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1, ml: 5 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={1} />
                    <TableCell>Data</TableCell>
                    <TableCell>Horário</TableCell>
                    <TableCell>Resumo</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bound.sessions.map((session) => (
                    <TableRow key={session.id}>
                      <TableCell width={1}>
                        <Checkbox checked={session.selected} onChange={() => toggleSession(session.id)} size='small' />
                      </TableCell>
                      <TableCell>
                        <Typography component='span'>{formatDate(session.start)}</Typography>
                        <IconButton component={Link} to={`/bounds/${bound.id}/session/${session.id}`}><Launch /></IconButton>
                      </TableCell>
                      <TableCell>{formatTimeRange(session.start, session.end)}</TableCell>
                      <TableCell>{session.summary}</TableCell>
                      <TableCell align="right">{formatMoney(session.sessionValue)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
