import { useSnackbar } from 'notistack'

export class ExpectedError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ExpectedError'
  }
}

export function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar()

  function sendMessage(toUser: string | string[] | undefined, toConsole: any) {
    if (toConsole) {
      console.error(toConsole)
    }

    if (toUser) {
      if (!Array.isArray(toUser)) {
        toUser = [toUser]
      }
      toUser.forEach((message) => {
        enqueueSnackbar(message, { variant: 'error' })
      })
    }
  }

  return {
    handleError(e: any, friendlyMessage?: string) {
      if (e instanceof ExpectedError) {
        return sendMessage(e.message || friendlyMessage, e)
      }

      if (e.graphQLErrors) {
        return sendMessage(e.graphQLErrors.flatMap((a: any) => a.extensions.originalError.message), null)
      }

      return sendMessage(friendlyMessage, e)
    }
  }
}


